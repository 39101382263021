
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import ExpansionPanel from '@/modules/common/components/ui-kit/expansion-panel.vue';
import CustomCheckbox from '@/modules/common/components/ui-kit/custom-checkbox.vue';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import CustomSwitch from '@/modules/common/components/ui-kit/custom-switch.vue';
import { HotelAlertsAndNotifications } from '@/modules/user/models/user-hotel-alerts.model';
import Item from '@/modules/common/interfaces/item.interface';
import HotelModel from '@/modules/hotels/models/hotel.model';
import type HotelsService from '@/modules/hotels/hotels.service';
import type CompsetsService from '@/modules/compsets/compsets.service';

import { HotelNotifications } from '../constants';
import { SettingsHotelService } from '../settings-hotel.service';

@Component({
    components: {
        CustomMultiSelect,
        CustomCheckbox,
        CustomSwitch,
        ExpansionPanel,
    },
})
export default class HotelNotificationsGroup extends Vue {
    @inject(KEY.HotelsService) private hotelsService!: HotelsService;
    @inject(KEY.SettingsHotelService) private settingsHotelService!: SettingsHotelService;
    @inject(KEY.CompsetsService) private compsetsService!: CompsetsService;

    private newSettings: HotelAlertsAndNotifications[0] | null = null;
    private changedFields: string[] = [];

    get competitors(): Item[] {
        if (!this.compsetsService.currentCompset) {
            return [];
        }

        return this.compsetsService.currentCompset.competitors.map(id => ({
            name: this.hotelsService.hotelNames[id],
            value: id,
        }));
    }

    get hotels(): Item[] {
        if (!this.compsetsService.currentCompset) {
            return [];
        }

        const { competitors, ownerHotelId } = this.compsetsService.currentCompset;

        return [...competitors, ownerHotelId]
            .map(id => ({
                name: this.hotelsService.hotelNames[id],
                value: id,
            }));
    }

    get isGroupEnabled() {
        return this.rateChangeActive || this.hotelSoldOutActive;
    }

    set isGroupEnabled(value) {
        this.rateChangeActive = value && !!this.rateChangeValue.length;
        this.hotelSoldOutActive = value && !!this.hotelSoldOutValue.length;
    }

    get isLoading() {
        const state = this.settingsHotelService.isAlertsAndNotificationsLoading;

        if (!state) {
            this.newSettings = structuredClone(this.currentSettings || {});
        }

        return state;
    }

    get hotelId() {
        return +this.$route.params.hotelId;
    }

    get rateChangeValue() {
        return this.getValue(HotelNotifications.COMPETITOR_RATE_CHANGE);
    }

    set rateChangeValue(values: Item[]) {
        this.setValue(HotelNotifications.COMPETITOR_RATE_CHANGE, values);

        if (values.length) {
            this.rateChangeActive = true;
        }
    }

    get rateChangeActive() {
        const alertOptions = this.getAlertOptions(HotelNotifications.COMPETITOR_RATE_CHANGE);

        if (!alertOptions || alertOptions.active === undefined) {
            return false;
        }

        return alertOptions.active;
    }

    set rateChangeActive(value: boolean) {
        this.updateActiveState(HotelNotifications.COMPETITOR_RATE_CHANGE, value);
    }

    get rateChangePercent() {
        const alertOptions = this.getAlertOptions(HotelNotifications.COMPETITOR_RATE_CHANGE);

        if (!alertOptions) return 15;

        return alertOptions.conditionsValue || 15;
    }

    set rateChangePercent(value: number) {
        this.updatePercentValue(HotelNotifications.COMPETITOR_RATE_CHANGE, +value);
    }

    set hotelSoldOutActive(value: boolean) {
        this.updateActiveState(HotelNotifications.HOTEL_SOLD_OUT, value);
    }

    get hotelSoldOutActive() {
        const alertOptions = this.getAlertOptions(HotelNotifications.HOTEL_SOLD_OUT);

        if (!alertOptions || alertOptions.active === undefined) {
            return false;
        }

        return alertOptions.active;
    }

    get hotelSoldOutValue() {
        return this.getValue(HotelNotifications.HOTEL_SOLD_OUT);
    }

    set hotelSoldOutValue(values: Item[]) {
        this.setValue(HotelNotifications.HOTEL_SOLD_OUT, values);

        if (values.length) {
            this.hotelSoldOutActive = true;
        }
    }

    get currentSettings() {
        return this.settingsHotelService.alertsAndNotifications[+this.$route.params.hotelId];
    }

    updatePercentValue(key: HotelNotifications, value: number) {
        const alertOptions = this.getAlertOptions(key);
        if (!alertOptions) return;

        alertOptions.conditionsValue = +value;

        this.addChangedField(key);
    }

    updateActiveState(key: HotelNotifications, value: boolean) {
        const alertOptions = this.getAlertOptions(key);

        if (!alertOptions) return;

        alertOptions.active = value;
        this.assignParametersFor({
            [key]: alertOptions,
        });

        this.addChangedField(key);
    }

    validateRateChangePercent(e: Event) {
        const input = e.currentTarget as HTMLInputElement;

        const max = Math.max(+input.value, 15);
        this.rateChangePercent = max;

        input.value = String(max);
    }

    getAlertOptions(key: HotelNotifications) {
        if (!this.newSettings?.[key] && !this.currentSettings?.[key]) {
            this.assignParametersFor({
                [key]: {
                    active: true,
                    fornovaIds: [],
                },
            });
        }

        return this.newSettings?.[key] || this.currentSettings[key];
    }

    assignParametersFor(newSettings: HotelAlertsAndNotifications[0]) {
        this.newSettings = {
            ...(this.newSettings || {}),
            ...newSettings,
        };
    }

    getValue(key: HotelNotifications) {
        const alertOptions = this.getAlertOptions(key);

        if (!alertOptions || !alertOptions.fornovaIds) {
            return [] as Item[];
        }

        return alertOptions.fornovaIds.map(id => {
            const item = this.hotels.find(option => option.value === id);
            return {
                name: item ? item.name : String(id),
                value: id,
            };
        });
    }

    setValue(key: HotelNotifications, values: Item[]) {
        const alertOptions = this.getAlertOptions(key);

        if (!alertOptions) return;

        alertOptions.fornovaIds = values.map(item => Number(item.value));

        if (!values.length) {
            alertOptions.active = false;
        }

        this.assignParametersFor({
            [key]: alertOptions,
        });

        this.addChangedField(key);
    }

    private addChangedField(key: string) {
        if (this.changedFields.includes(key)) return;
        this.changedFields.push(key);
        this.$emit('change');
    }

    toggleGroup() {
        this.isGroupEnabled = !this.isGroupEnabled;
    }

    async save() {
        const updatedSettings = this.changedFields.reduce((acc, key) => {
            if (!this.newSettings) return acc;

            const { active, fornovaIds, conditionsValue } = this.newSettings[key];
            acc[key] = { active, fornovaIds, conditionsValue };

            return acc;
        }, {} as HotelAlertsAndNotifications[0]);

        await this.settingsHotelService.saveAlertsAndNotifications(+this.$route.params.hotelId, updatedSettings);
    }
}
