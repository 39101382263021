import { render, staticRenderFns } from "./alerts-notifications.page.vue?vue&type=template&id=dd99e180&scoped=true&"
import script from "./alerts-notifications.page.vue?vue&type=script&lang=ts&"
export * from "./alerts-notifications.page.vue?vue&type=script&lang=ts&"
import style0 from "./alerts-notifications.page.vue?vue&type=style&index=0&id=dd99e180&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd99e180",
  null
  
)

export default component.exports