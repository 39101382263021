
import _ from 'lodash';
import { Vue, Prop, Component } from 'vue-property-decorator';
import { fieldsConfig } from '../config/alerts-notification.config';
import { AlertsAndNotifications } from '../interfaces/alerts-and-notifications.interface';

const PARAMETERS_REGEX = /\{(.+?)\}/g;
const STRINGS_REGEX = /\{.+?\}/;

@Component
export default class DynamicDescription extends Vue {
    @Prop({ type: Object })
    public params!: AlertsAndNotifications['param'];

    @Prop({ type: String })
    public paramKey!: string;

    @Prop({ type: String })
    public groupKey!: string;

    private get sourceString() {
        return this.$t(`settings.alerts.${_.camelCase(this.paramKey)}Msg`) as string;
    }

    public get strings() {
        if (!this.sourceString) return [];

        return this.sourceString.split(STRINGS_REGEX);
    }

    public get fields() {
        if (!this.sourceString) return [];

        const params = this.sourceString
            .match(PARAMETERS_REGEX);

        return (params || [])
            .map(fieldIndex => fieldsConfig[this.paramKey].fields![+fieldIndex[1]]);
    }

    public handleInput(key: string, value: string) {
        this.$emit('input', { key, value });
    }
    public handleChange(key: string, value: string) {
        this.$emit('change', { key, value });
    }
}
